import { useState, useEffect, useMemo, useCallback, Image } from "react";
import axios from "axios";
export interface Props {}

const LandingPage = (props: Props) => {
  const endpoint = "https://signup-api.earthof.com/data";
  const [card, setCard] = useState("main");
  const [showModal, setShowModal] = useState(false);
  const [afterSubmit, setAfterSubmit] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const storedClaims = localStorage.getItem("claims");
  const [formData, setFormData] = useState({
    shell_name: "",
    email: "",
  });

  const activeClaims = useMemo(() => {
    return storedClaims ? JSON.parse(storedClaims) : [];
  }, [storedClaims]);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkValues = useCallback(() => {
    let check = [];
    const nameMinLength = 3;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (card === "claim") {
      if (formData.shell_name.length < nameMinLength) {
        check.push("Shell name must be at least 3 characters long.");
      }
      if (activeClaims.includes(formData.shell_name)) {
        check.push("You already have an active claim for this shell name.");
      }
    }
    if (!emailPattern.test(formData.email)) {
      check.push("E-Mail address must be valid.");
    }
    setErrors(check);
  }, [formData, activeClaims, card]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (errors.length === 0) {
      try {
        const res = await axios.post(endpoint, formData);
        console.log("Res:", res.data);
        let claims = activeClaims;
        if (!claims.length) {
          claims = [formData.shell_name];
        } else {
          claims.push(formData.shell_name);
        }
        if(formData.shell_name) localStorage.setItem("claims", JSON.stringify(claims));

        setFormData({ shell_name: "", email: "" });
        setCard("main");
        setShowModal(false);
      } catch (err) {
        console.error("Err:", err);
      }
    } else {
      setAfterSubmit(true);
    }
  };

  useEffect(() => {
    setFormData({ shell_name: "", email: "" });
  }, [card]);

  useEffect(() => {
    checkValues();
  }, [formData, checkValues]);

  return (
    <>
    <div className="title">
      <h1>EARTHoF.</h1>
      <img src={require('./logo.png')} />
    </div>    
    
    <div className="landing__content">
      <div className="landing__card" style={{ marginLeft: 0 }}>
        <h2>Reddit Sucks & MGMT doesn't care.</h2>
        <p>
          EARTHoF is: Hyper aggregation of everything + Community discussion (subreddit = shell).
          <br />
          We are open source: If we fail you (the community), you can fire us and take everything.
        </p>

        <div className="landing__options">
          <div className="landing__option" onClick={() => {
            setCard("claim")
            setShowModal(true);
          }}>
            Mod claim for shell
          </div>
          <div className="landing__option" onClick={() => {
            setCard("updates")
            setShowModal(true);
          }}>
            Get Updates
          </div>
        </div>

        {activeClaims && activeClaims.length > 0 && (
          <div className="landing__stored">
            <h3>Active claims</h3>
            <>
              {activeClaims.map((entry: any, i: number) => (
                <>
                  {entry && (
                    <div key={i}>
                      {entry}
                    </div>
                  )}
                </>                  
              ))}
            </>
          </div>
        )}
      </div>
    </div>
    {showModal && (
      <div className="modal__container" onClick={(e) => {
        e.stopPropagation()
        setShowModal(false)}
      }>
      <div className="modal"onClick={(e) => e.stopPropagation()}>
        {card === "claim" && (
            <div className="landing__card">
              <h2>MOD CLAIM FOR SHELL</h2>
              <form onSubmit={handleSubmit}>
                <div className="landing__group">
                  <div className="landing__value">Name</div>
                  <div className="landing__action">
                    <input type="text" name="shell_name" value={formData.shell_name} onChange={handleChange} />
                    <div className="landing__action-description">
                      Mods of subreddits can claim the same name for their shell. We will contact you to validate mod
                      status.
                    </div>
                  </div>
                </div>
                <div className="landing__group">
                  <div className="landing__value">email</div>
                  <div className="landing__action">
                    <input type="text" name="email" value={formData.email} onChange={handleChange} />
                  </div>
                </div>
                <button type="submit">Submit</button>
              </form>
              <div className="landing__errors">
                {afterSubmit && errors && (
                  <>
                    {errors.map((entry, i) => (
                      <div key={i}>{entry}</div>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
          {card === "updates" && (
            <div className="landing__card">
              <h2>GET UPDATES</h2>
              <form onSubmit={handleSubmit}>
                <div className="landing__group">
                  <div className="landing__value">email</div>
                  <div className="landing__action">
                    <input type="text" name="email" value={formData.email} onChange={handleChange} />
                    <div className="landing__action-description">
                      Be informed of when sign up is available and other important stuff we can't think off right now but
                      that you'd probably want to know.
                    </div>
                  </div>
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
        </div>
      </div>
    )}
    
    </>
  );
};

export default LandingPage;
